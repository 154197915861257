html{
  min-height: 100%;
}

button{
  width: 150px !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #95e8f3;
  background-position: bottom;
  background-size: 100%;
  height: 100vh;
}

#root{
  height: 100vh;
}

form{
  padding: 20px;
}

form button, form h5{
  margin: 20px 0;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #ec407a;
  box-shadow: none;
}

input[type=text]:not(.browser-default):focus:not([readonly]) + label,
input[type=date]:not(.browser-default):focus:not([readonly]) + label
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label{
  color: #ec407a !important;
}

tr, th, td{
  padding: 0.3%;
}

a.white {
  color: white;
  text-decoration: none;
}

a.white:hover{
  color: white;
  text-decoration: none;
}

.sidebar {
  background-color: #f5f5f5;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  overflow-y: auto;
}

.main-content {
  padding: 20px;
  margin-left: 300px; /* Set the same width as your sidebar */
}

.custom-radio .form-check-input {
  background-color: #fff; /* Dark background color */
  border-color: #333; /* Dark border color */
  color: #fff; /* Text color */
}